.card {
  background: #fff;
  border: 2px solid #fff;
  position: relative;
  padding: 22px 0 32px 32px;
  text-align: left;
  margin-bottom: 40px;
  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 0px 2px 12px 0px #e3e6f3;

  &:hover {
    border: 2px solid var(--app-primary-light-color);
  }

  &:last-child {
    margin-bottom: 0;
  }

  > .title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    margin-bottom: 12px;
  }

  > .desc {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #909093;
    line-height: 22px;
    // width: 55%;
    margin: 0;
    padding: 0;
  }
}

.content {
  color: var(--app-text-color);
  //
  margin-bottom: 100px;
  margin-top: 100px;
  padding: 1rem;
  .contentTitle {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--app-text-color);
    margin-bottom: 60px;
  }

  p {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    color: #909093;
    line-height: 1.2;
    margin-bottom: 50px;
  }

  > .bottomContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;

    > .leftSubContainer {
      &:first-child {
        margin-right: 20px;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (min-width: 899px) {
  .content {
    margin-bottom: 100px;

    > .bottomContainer {
      grid-template-columns: 1fr 1fr;

      > .leftSubContainer {
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .content {
    margin-bottom: 100px;

    > .bottomContainer {
      grid-template-columns: 1fr 1fr;

      > .leftSubContainer {
        &:first-child {
          margin-right: 20px;
        }
        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-bottom: 100px;

    > .bottomContainer {
      grid-template-columns: 1fr;

      > .leftSubContainer {
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-top: 40px;
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    margin-bottom: 50px;

    h2 {
      font-size: 32px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
      margin-bottom: 34px;
    }

    > .bottomContainer {
      grid-template-columns: 1fr;

      > .leftSubContainer {
        &:first-child {
          margin-right: 0;
        }
        &:last-child {
          margin-top: 40px;
          margin-left: 0;
        }
      }
    }
  }
}

