.content {
  background: var(--foreground-rgb);
  color: var(--app-text-color);
  margin-top: 50px;
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 32px;
    color: var(--app-text-color);
    margin-bottom: 10px;
  }
  .contentDesc {
    text-align: center;
    padding: 0 100px;
  }
  p {
    font-size: 24px;
    font-weight: 400;
    color: #909093;
    line-height: 1.2;
    margin-bottom: 50px;
    padding: 1rem;
  }
  .imgContainer {
    text-align: center;
    padding: 1rem;
    max-width: 1060px;
    margin: 0 auto;

    > img {
      object-fit: contain;
      width: 100%;
    }
  }

  > h2 {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: var(--app-text-color);
    margin: 50px 0;
  }
  > .container {
    .card {
      display: flex;
      padding: 100px 0;
      justify-content: center;
      background: var(--foreground-rgb);

      // margin: 0 auto;
      // padding: 1rem;
      > .cardContainer {
        max-width: 1060px;
        display: flex;
        // align-items: center;

        > .leftCardContent {
          flex: 1;
          text-align: left;
          margin-right: 50px;

          > .title {
            font-size: 36px;
            color: var(--app-text-color);
            line-height: 1.2;
          }

          > .desc {
            margin-top: 20px;
            font-weight: 400;
            font-size: 20px;
            color: var(--app-text-60-color);
            line-height: 1.2;
          }
        }

        > .rightCardContent {
          flex: 1;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          > img {
            aspect-ratio: 499/314;
            object-fit: contain;
            width: 100%;
          }
        }
      }
      &:nth-child(2n) {
        background: #e7f2e9;

        .cardContainer {
          flex-direction: row-reverse;

          > .leftCardContent {
            text-align: right;
            margin-right: 0;
          }

          > .rightCardContent {
            margin-right: 50px;
          }
        }
      }
      &:last-child {
        .cardContainer {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    .contentDesc {
      padding: 0 20px;
    }
    > .container {
      .card {
        padding: 50px;
        > :nth-child(2) {
          .cardContainer {
            margin-top: 20px;
          }
        }
        &:nth-child(2n) {
          .cardContainer {
            > .leftCardContent {
              text-align: center;
            }

            > .rightCardContent {
              margin-right: 0;
            }
          }
        }

        > .cardContainer {
          max-width: 1060px;
          margin: 0 auto;
          padding: 1rem;
          display: block;
          text-align: center;
          // align-items: center;
          // padding: 50px;

          > .leftCardContent {
            text-align: center;
            margin-right: 0;
          }
        }
      }
    }
  }
}

